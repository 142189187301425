import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';
import AppMobile from './AppMobile';
import AppDesktop from './AppDesktop';
import reportWebVitals from './reportWebVitals';

import { BrowserView, MobileView } from 'react-device-detect';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
      <BrowserView>
      <div id="game-container-desktop">
          <AppDesktop />
        </div>
      </BrowserView>
      <MobileView>
        <div id="game-container-mobile">
          <AppMobile />
        </div>
      </MobileView>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
