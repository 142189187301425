import Phaser from "phaser";
import GameData from "./GameData";
import GameObject from "./GameObject";

export default interface PlayerBar {

	 body: Phaser.Physics.Arcade.Body;
}

export default class PlayerBar extends Phaser.GameObjects.Image implements GameObject {
	
	private gameData!: GameData;	
	private barSpeed!: number;
	private offsetAllowed!: number;

	constructor(scene: Phaser.Scene, gameData: GameData, x?: number, y?: number) {
		super(scene, x ?? 97, y ?? 19.875, "PlayerBar");

		this.gameData = gameData;

		this.barSpeed = 350;
		this.offsetAllowed = 3;

		this.scaleX = 0.75;
		this.scaleY = 0.75;
		scene.physics.add.existing(this, false);
		this.body.allowGravity = false;
		this.body.pushable = false;
		this.body.setImmovable(false);
		this.body.setCollideWorldBounds(true);
		this.body.setOffset(13, 22);
		this.body.setSize(230, 12, false);
	}

	startGame()	{}

	update() {
		let pointer = this.gameData.input.activePointer;
		this.body.setVelocity(0);
		
		if (pointer.isDown && this.gameData.gameStarted) { 
			var touchX = pointer.x;
			var touchY = pointer.y;
			this.gameData.touchTextX?.setText('TouchX: ' + touchX);
			this.gameData.touchTextY?.setText('TouchY: ' + touchY);
	
			if (touchX < this.body.center.x - this.offsetAllowed) {
				this.body.setVelocityX(-this.barSpeed);
			} else if (touchX > this.body.center.x + this.offsetAllowed) {
				this.body.setVelocityX(this.barSpeed); 
			} else {
				this.body.setVelocityX(0);
			}
		} else {
			this.body.setVelocityX(0);   
		}
	}
}

// TODO: Create a parent class for the bars