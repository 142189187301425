import Phaser from "phaser";
import GameData from "./GameData";
import EnemyBar from "./EnemyBar";
import PlayerBar from "./PlayerBar";
import Ball from "./Ball";
import InvisibleCollider from "./InvisibleCollider";
import GameObject from "./GameObject";

export default class Level extends Phaser.Scene {

	private playerBar!: PlayerBar;
	private enemyBar!: EnemyBar;
	private ball!: Ball;
	private playerBarsOffsetY!: number;
	private ememyBarsOffsetY!: number;
	private middleScreenPointX!: number;
	private middleScreenPointY!: number;
	private gameMiddlePointY!: number;

	private betText!: Phaser.GameObjects.Text;
	private roundText!: Phaser.GameObjects.Text;
	private player1Text!: Phaser.GameObjects.Text;
	private player2Text!: Phaser.GameObjects.Text;
	private pingText!: Phaser.GameObjects.Text;

	private gameData!: GameData; // Information container to be shared to other game objects
	private gameObjects!: GameObject[]; // All game objects that needs to be notified of main events

	constructor() {
		super("Level");
	}

	editorCreate(): void {
		
		this.gameData = { gameStarted: false, input: this.input, ball: this.ball };

		// Scaling
		this.playerBarsOffsetY = 0.04 * this.scale.height; // Move player bars 5% from the scene screen bottom limit
		this.ememyBarsOffsetY = 0.16 * this.scale.height; // Move enemy bars 25% from the scene screen top limit
		this.middleScreenPointX = this.scale.width / 2;
		this.middleScreenPointY= this.scale.height / 2;		
		this.gameMiddlePointY = this.middleScreenPointY + (0.06 * this.scale.height); // Game field middle point

		// background
		this.add.image(this.middleScreenPointX, this.middleScreenPointY, "BackgroundUI");

		this.setupDebugFeedback();
		this.addGameTexts();

		// ball
		const ball = new Ball(this, this.middleScreenPointX, this.gameMiddlePointY);
		this.add.existing(ball);

		// enemyBar
		const enemyBar = new EnemyBar(this, this.gameData, this.middleScreenPointX, this.ememyBarsOffsetY);
		this.add.existing(enemyBar);

		// playerBar
		const playerBar = new PlayerBar(this, this.gameData, this.middleScreenPointX, this.scale.height - this.playerBarsOffsetY);
		this.add.existing(playerBar);		

		// lists
		const leftCollider = new InvisibleCollider(this, this.scale.width * 0.025, this.gameMiddlePointY, this.scale.width * 0.045, this.scale.height * 0.85);
		const rightCollider = new InvisibleCollider(this, this.scale.width - (this.scale.width * 0.025), this.gameMiddlePointY, this.scale.width * 0.045, this.scale.height * 0.85);
		const topCollider = new InvisibleCollider(this, this.middleScreenPointX, 0, this.scale.width, this.scale.height * 0.27);
		const bottomCollider = new InvisibleCollider(this, this.middleScreenPointX, this.scale.height - (this.scale.height * 0.005), this.scale.width, this.scale.height * 0.02);

		// ingameColliders
		const playerBars = [playerBar, enemyBar, leftCollider, rightCollider, topCollider, bottomCollider];
		this.physics.add.collider(playerBars, ball);
		this.physics.add.collider(playerBars, playerBars);
		
		this.playerBar = playerBar;
		this.enemyBar = enemyBar;
		this.ball = ball;		
		this.gameData.ball = this.ball;


		// TODO: Move button to a separate / reusable class
		var sprite = this.add.sprite(this.middleScreenPointX, this.gameMiddlePointY, 'play_sprite_sheet').setInteractive();
		sprite.setFrame(1);

		sprite.on('pointerdown', function (pointer: object) {
			sprite.setFrame(0);
		});

		sprite.on('pointerout', function (pointer: object) {
			sprite.setFrame(1);
		});

		sprite.on('pointerover', function (pointer: object) {
			sprite.setFrame(2);
		});

		sprite.on('pointerup',  (pointer: object) => {			
			this.startGame();
			sprite.setActive(false).setVisible(false);
		});

		this.events.emit("scene-awake");

		// Game objects
		this.gameObjects = [this.playerBar, this.enemyBar, this.ball];
	} 

	create() {
		this.editorCreate();
	}

	update(time: number, delta: number): void {
		this.gameObjects.forEach(function (gameObject) {
			gameObject.update();
		});
	}

	startGame()
	{
		this.gameData.gameStarted = true;
		this.gameObjects.forEach(function (gameObject) {
			gameObject.startGame();
		});		
	}

	addGameTexts(): void
	{
		let fontFamily = 'Tahoma, Verdana, sans-serif';

		const betText = this.add.text(this.middleScreenPointX, this.scale.height * 0.03, '1.2 USDT', {fontFamily: fontFamily, fontSize: '25px'}).setOrigin(0.5);
		const roundText = this.add.text(this.middleScreenPointX, this.scale.height * 0.11, 'Round: 5/10', {fontFamily: fontFamily, fontSize: '20px'}).setOrigin(0.5);
		const player1Text = this.add.text(this.scale.width * 0.15, this.scale.height * 0.0625, 'Player1: 2', {fontFamily: fontFamily, fontSize: '22px'}).setOrigin(0.5);
		const player2Text = this.add.text(this.scale.width - (this.scale.width * 0.15), this.scale.height * 0.0625, 'Player2: 3', {fontFamily: fontFamily, fontSize: '22px'}).setOrigin(0.5);
		const pingText = this.add.text(this.scale.width - (this.scale.width * 0.1), this.scale.height * 0.125, 'Ping: 110 ms', {fontFamily: fontFamily, fontSize: '14px'}).setOrigin(0.5);

		this.betText = betText;
		this.roundText = roundText;
		this.player1Text = player1Text;
		this.player2Text = player2Text;
		this.pingText = pingText;
	}	

	setupDebugFeedback(): void
	{
		console.log('physics.world.bounds.width: ' + this.physics.world.bounds.width);
		console.log('physics.world.bounds.width: ' + this.physics.world.bounds.height);
		console.log('scale.width: ' + this.scale.width);
		console.log('scale.height: ' + this.scale.height);
		console.log('scene.scale.width: ' + this.scene.scene.scale.width);
		console.log('scene.scale.height: ' + this.scene.scene.scale.height);

		let fontSettings = 
		{
			fontFamily: 'Monaco, Courier, monospace',
			fontSize: '14px',
			//fill: '#fff'
		};

		const touchTextX = this.add.text(50, this.scale.height - 120, 'TouchX: ', fontSettings);
		const touchTextY = this.add.text(50, this.scale.height - 140, 'TouchY: ', fontSettings);

		this.gameData.touchTextX = touchTextX;
		this.gameData.touchTextY = touchTextY;
	}
}