import Phaser from "phaser";
import GameObject from "./GameObject";

export default interface Ball {

	 body: Phaser.Physics.Arcade.Body;
}

export default class Ball extends Phaser.GameObjects.Image implements GameObject {

	private ballSpeed!: number;

	constructor(scene: Phaser.Scene, x?: number, y?: number) {
		super(scene, x ?? 38, y ?? 18, "Ball");

		this.ballSpeed = 400;
		
		this.scaleX = 0.25;
		this.scaleY = 0.25;
		scene.physics.add.existing(this, false);
		this.body.friction.x = 0;
		this.body.bounce.x = 1;
		this.body.bounce.y = 1;
		this.body.setCollideWorldBounds(true);
		this.body.setOffset(20, 20);
		this.body.setCircle(44);
	}

	startGame()
	{
		this.body.setMaxVelocity(this.ballSpeed, this.ballSpeed);
		const initialXSpeed = Math.random() * this.ballSpeed;
		const initialYSpeed = this.ballSpeed;
		this.body.setVelocityX(initialXSpeed);
		this.body.setVelocityY(initialYSpeed);
	}

	update() 
	{
		
	}
}