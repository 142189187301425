import './AppMobile.css'

import Phaser from "phaser";
import Level from "./scenes/Level";
import Preload from "./scenes/Preload";
// import io from "socket.io-client";


let game: any = null;
let socket: any = null;

class Boot extends Phaser.Scene {

	constructor() {
		super("Boot");
	}

	preload() {
		this.load.pack("pack", "assets/preload-asset-pack.json");
	}

	create() {
		this.scene.start("Preload");
	}
}

function AppMobile() {
	console.log(window.innerWidth)
	console.log(window.innerHeight);
	console.log("Mobile");
	// socket = io("http://192.168.0.2:5000");
	
	// socket.on('disconnect', function(){
	// 	console.log('disconnected');
	// });
	const config = {
		backgroundColor: "#000000",
		scale: {
			mode: Phaser.Scale.ScaleModes.FIT,
			autoCenter: Phaser.Scale.Center.CENTER_HORIZONTALLY,
			width: 720,
			height: 1280
		},
		physics:
		{
			default: "arcade"
		},
		scene: [Boot, Preload, Level]
	};

	if (game === null) {
		console.log('Loading Phaser');
		game = new Phaser.Game(config);
		game.scene.start("Boot");
	}

	return <></>
}

export default AppMobile;
