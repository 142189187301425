import React from 'react';
import './AppDesktop.css';

import Phaser from "phaser";
import Level from "./scenes/Level";
import Preload from "./scenes/Preload";
import io from "socket.io-client";

let game: any = null;
let socket: any = null;

class Boot extends Phaser.Scene {

	constructor() {
		super("Boot");
	}

	preload() {
		this.load.pack("pack", "assets/preload-asset-pack.json");
	}

	create() {
		this.scene.start("Preload");
	}
}

function AppDesktop() {
	console.log(window.innerWidth)
	console.log(window.innerHeight);
	console.log("Desktop");
	// socket = io("http://localhost:5000");
	// socket.on("connect", function() {
	// 	console.log('Connected');
	// 	socket.emit("init_connection", {data: socket.id});
	// });
	const config = {
		// width: window.innerWidth, //720,
		// height: window.innerHeight, //1280,
		backgroundColor: "#000000",
		scale: {
			mode: Phaser.Scale.ScaleModes.FIT,
			autoCenter: Phaser.Scale.Center.CENTER_HORIZONTALLY,
			width: 720,
			height: 1280
		},
		physics:
		{
			default: "arcade"
		},
		scene: [Boot, Preload, Level]
	};

	if (game === null) {
		console.log('Loading Phaser');
		game = new Phaser.Game(config);
		game.scene.start("Boot");
	}

	return <></>
}

export default AppDesktop;

// For colliders visualization, add this inside physics:
//
//	arcade: {
//		debug: true
//	}
