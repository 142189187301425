import Phaser from "phaser";

export default interface InvisibleCollider {

	 body: Phaser.Physics.Arcade.Body;
}

export default class InvisibleCollider extends Phaser.GameObjects.Image {

	constructor(scene: Phaser.Scene, x?: number, y?: number, sizeX?: number, sizeY?: number, frame?: number | string) {
		super(scene, x ?? 0, y ?? 0, "EnemyBar", frame);

		this.alpha = 0;
		scene.physics.add.existing(this, false);
		this.body.allowGravity = false;
		this.body.pushable = false;
		this.body.immovable = true;
		this.body.setSize(sizeX, sizeY, true);
	}
}