import Phaser from "phaser";
import GameData from "./GameData";
import GameObject from "./GameObject";

export default interface EnemyBar {

	 body: Phaser.Physics.Arcade.Body;
}

export default class EnemyBar extends Phaser.GameObjects.Image implements GameObject{
	private gameData!: GameData;
	private barSpeed!: number;	
	private offsetAllowed!: number;

	constructor(scene: Phaser.Scene, gameData: GameData, x?: number, y?: number) {
		super(scene, x ?? 16, y ?? 0, "EnemyBar");

		this.gameData = gameData;

		this.barSpeed = 350;		
		this.offsetAllowed = 3;

		this.scaleX = 0.75;
		this.scaleY = 0.75;
		scene.physics.add.existing(this, false);
		this.body.allowGravity = false;
		this.body.pushable = false;
		this.body.setImmovable(false);	
		this.body.setCollideWorldBounds(true);
		this.body.setOffset(13, 22);
		this.body.setSize(230, 12, false);
	}

	startGame() {}

	update() 
	{
		if (this.gameData.gameStarted) { 
			var posX = this.gameData.ball.x;

			if (posX < this.body.center.x - this.offsetAllowed) {
				this.body.setVelocityX(-this.barSpeed);
			} else if (posX > this.body.center.x + this.offsetAllowed) {
				this.body.setVelocityX(this.barSpeed); 
			} else {
				this.body.setVelocityX(0);
			}
		} else {
			this.body.setVelocityX(0);   
		}
	}
}

// TODO: Create a parent class for the bars